<main>
    <div class="posts-container container-fluid">
        <div class="row pt-3 g-3">
            <div *ngFor="let p of posts" class="col-lg-4 col-md-6 col-xs-12">
                <div (click)="goTo(p)" class="post-item p-3">
                    <h2>{{p.name}}</h2>
                    <p class="description"> {{p.intro}} </p>
                    <div class="last-post">
                        <p class="p-0 m-0">posted on: {{p?.postedOn.toDate() | date:'dd-mm-yyyy HH:MM'}} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>