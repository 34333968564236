import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Mainservice } from '../../services/mainservice.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-blog-topics',
  templateUrl: './blogtopics.component.html',
  styleUrls: ['./blogtopics.component.scss']
})
export class BlogTopicsComponent implements OnInit {

  topics: any = [];

  constructor(private router: Router, 
              private mService: Mainservice, 
              private activatedRoute: ActivatedRoute,
              private db: AngularFirestore) { }

  ngOnInit(): void {
    this.getTopics();
  }

  goTo(topic: any){
    this.mService.selectedTopic = topic.name;
    this.router.navigate(['topics',topic.id], {relativeTo: this.activatedRoute});
  }

  getTopics(){
    this.db.collection('blog-topics').valueChanges().subscribe(data => {
      this.topics = data;
    });
  }

}
