import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  welkomtekst : String = "w,e,l,c,o,m,e2,.";

  constructor() { }

  ngOnInit(): void {
    // this.createAnimation();
  }

  // async createAnimation(){
  //   let welkomtekst = this.welkomtekst.split(",");
  //   let count = 0;

  //   let interval = setInterval(() => {
  //     if (count == welkomtekst.length){
  //       clearInterval(interval);
  //     } else {
  //       let el = document.getElementsByClassName(welkomtekst[count])[0];
  //       el.classList.add("show");
  //       el.classList.add("animate__animated");
  //       el.classList.add("animate__zoomIn");
  //       count++;
  //     }
  //   }, 200);
  // }

}
