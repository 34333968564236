import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BlogpageComponent } from './blog/blogpage/blogpage.component';
import { BlogTopicsComponent } from './blog/blogtopics/blogtopics.component';
import { BlogpostsComponent } from './blog/blogposts/blogposts.component';
import { BlogViewComponent } from './blog/blog-view/blog-view.component';



const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'blog', component: BlogpageComponent, children: [
    {path: '', component: BlogTopicsComponent},
    {path: 'topics/:topicId', component: BlogpostsComponent},
    {path: 'post/:postId', component: BlogViewComponent}
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
