<h1>The topics I write about.</h1>
<div class="posts-container container-fluid">
    <div class="row pt-3 g-3">
        <div *ngFor="let t of topics" class="col-lg-4 col-md-6 col-xs-12">
            <div (click)="goTo(t)" class="post-item p-3">
                <h2>{{t.name}}</h2>
                <div class="container-fluid py-2 px-0 description-box mt-3">
                    <p class="description"> {{t.description}} </p>
                </div>
            </div>
        </div>
    </div>
</div>