<div class="menu-bar-container">

    <div class="opt-item">      
        <div routerLink="" class="menu-opt">
            <mat-icon>description</mat-icon>
        </div>  
        <div class="option-title">Ervaring</div>
    </div>

    <div class="opt-item">
        <div routerLink="" class="menu-opt">
            <mat-icon>home</mat-icon>
        </div>   
        <div class="option-title">Home</div>
    </div>

    <div class="opt-item">
        <div routerLink="/blog" class="menu-opt">
            <mat-icon>create</mat-icon>
        </div>  
        <div class="option-title">Blog</div>
    </div>
</div>