import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Mainservice {

  sub:Subscription;
  topic: string;
  selectedTopicId: string;

  constructor(protected route: ActivatedRoute) { 
  }

  selectedTopic: string = "";
  selectedPost: string = "";
}
