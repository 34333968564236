<main class="blog-container">
    <h1> {{blogPost?.name}} </h1>

    <div class="container-fluid p-0 my-3">
        <div class="line-and-views py-2 my-3">
            <div class="posted-on">
                <span>Posted on: {{blogPost?.postedOn.toDate() | date:'dd-mm-yyyy HH:MM'}}</span>
            </div> 
            <div class="views-box">
                <mat-icon class="mx-2" color="white">visibility</mat-icon>
                <span>{{blogPost?.views}} views</span>
            </div>    
        </div>
        <div class="text-box" [innerHTML]="blogPost?.post"></div>
    </div>
</main>