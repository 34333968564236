import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { MenubarComponent } from './menubar/menubar.component';
import { MatIconModule } from '@angular/material/icon';
import { BlogpageComponent } from './blog/blogpage/blogpage.component';
import { BlogTopicsComponent } from './blog/blogtopics/blogtopics.component';
import { BlogpostsComponent } from './blog/blogposts/blogposts.component';
import { Mainservice } from './services/mainservice.service';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BlogViewComponent } from './blog/blog-view/blog-view.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenubarComponent,
    BlogpageComponent,
    BlogTopicsComponent,
    BlogpostsComponent,
    BlogViewComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule
  ],
  providers: [Mainservice],
  bootstrap: [AppComponent]
})
export class AppModule { }
