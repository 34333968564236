<div class="home-container">
    <div class="title-container">
        <h1>
            <span class="w">W</span>
            <span class="e">e</span>
            <span class="l">l</span>
            <span class="c">c</span>
            <span class="o">o</span>
            <span class="m">m</span>
            <span class="e2">e</span>
            <span class=".">.</span>
        </h1>
    </div>
    <div class="body-container">
        <p class="mt-4">Hier komt mijn (Shafie's) website.</p>
    </div>
</div>
