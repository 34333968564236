import { Component, OnInit } from '@angular/core';
import { Mainservice } from '../../services/mainservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blogpage',
  templateUrl: './blogpage.component.html',
  styleUrls: ['./blogpage.component.scss']
})
export class BlogpageComponent implements OnInit {

  constructor(public mService: Mainservice, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.mService.selectedTopic = undefined;
    this.mService.selectedTopicId = undefined;
  }

  goHome(){
    this.router.navigate(['blog']);
    this.mService.selectedTopic = undefined;
    this.mService.selectedTopicId = undefined;
  }

  goToTopics(){
    if (!this.router.url.includes('topics')){
      this.router.navigate(['topics', this.mService.selectedTopicId], {relativeTo: this.route});
    }
  }
}
  