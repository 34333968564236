import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Mainservice } from '../../services/mainservice.service';

@Component({
  selector: 'app-blogposts',
  templateUrl: './blogposts.component.html',
  styleUrls: ['./blogposts.component.scss']
})
export class BlogpostsComponent implements OnInit {

  posts: any[] = [];
  selectedCategories: any;
  sub: Subscription;

  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private mService: Mainservice,
    private fs: AngularFirestore
    ) { }

  ngOnInit(): void {
    this.getPosts();
  }

  getPosts(){
    this.sub = this.activatedRoute.params.subscribe( params => {
      this.fs.collection('blog-posts', ref => ref.where('topics','array-contains', params['topicId'])).valueChanges()
      .subscribe( data => {
        this.posts = data;
      });

      this.fs.collection('blog-topics').doc(params['topicId']).valueChanges()
      .subscribe( data2 => {
        this.mService.selectedTopic = data2['name'];
      });
    });
  }

  goTo(post: any){
    this.router.navigate(['post',post.id], {relativeTo: this.activatedRoute.parent});
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
