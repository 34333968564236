import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Mainservice } from './../../services/mainservice.service';
import firebase from 'firebase/app';

@Component({
  selector: 'app-blog-view',
  templateUrl: './blog-view.component.html',
  styleUrls: ['./blog-view.component.scss']
})
export class BlogViewComponent implements OnInit {

  sub: Subscription;
  constructor(private route: ActivatedRoute, private router: Router, private db: AngularFirestore, private mService: Mainservice) { }

  blogPost: any;

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.route.params.subscribe( params => {

      let docref = this.db.collection('blog-posts').doc(params['postId']);
      let blogItem = sessionStorage.getItem(params['postId']);

      if (!blogItem){
        sessionStorage.setItem(params['postId'],'true');

        docref.update({
          views: firebase.firestore.FieldValue.increment(1)
        });
      }

      docref.valueChanges()
      .subscribe( data => {
        this.blogPost = data;

        this.db.collection('blog-topics').doc(data['topics'][0]).valueChanges()
        .subscribe( data2 => {
          this.mService.selectedTopic = data2['name'];
          this.mService.selectedTopicId = data2['id'];
        })
      });
    });
  }

}
